<template>
  <SceneSetup
    class="sky"
    ref="sceneContainer"
    :pageId="pageId"
    :content="content"
    :scenes="scenes"
    :clickedSphere="clickedSphere"
    :currentSceneIndex="currentSceneIndex"
    :loaded="loaded"
    @onSwipeStart="onSwipeStart"
    @onSwipe="onSwipe"
    @closeDetails="closeDetails"
    @setNewScene="changeSceneFromMenu"
    @toProject="navigateToProject"
    @toggleInteraction="setInteract"
  />
</template>

<script>
import { threeSetup } from "@/mixins/three-setup";
import { Color, RGBAFormat, TextureLoader } from "three";
import SceneSetup from "@/components/UI/SceneSetup.vue";
import { mapActions } from "vuex";

export default {
  components: { SceneSetup },
  mixins: [threeSetup],
  data() {
    // All the data here should specific to the Cielo scenes
    return {
      pageId: "sky",
      scenesConfig: [
        {
          folder: "cloud",
          scale: 9,
          position: { y: -2, z: 0.05, x: -0.15 },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: -0.7 },
          ringGroupRotation: { x: Math.PI * 0.45, z: Math.PI * 0.05 },
          modelCallback: this.makeCloudTransparent
        },
        {
          folder: "drone",
          scale: 17,
          position: { y: -1, z: 0.35, x: -0.45 },
          rotation: { x: Math.PI * 0.08 },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: -0.2 },
          ringGroupRotation: { x: Math.PI * 0.45 }
        },
        {
          folder: "feathers",
          scale: 1.5,
          position: { y: -3.5, z: -3, x: -1 },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: -0.4 },
          ringGroupRotation: { x: Math.PI * 0.59 }
        },
        {
          folder: "network",
          scale: 1,
          position: { y: -0.3, x: 0.5 },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: -0.4 },
          ringGroupRotation: { x: Math.PI * 0.55 }
        }
      ],
      gradientColor: {
        color1: {
          value: new Color("hsl(209, 40%, 95%)")
        },
        color2: {
          value: new Color("hsl(209, 80%, 70%)")
        },
        color3: {
          value: new Color("hsl(209, 50%, 75%)")
        },
        step1: {
          value: 0.0
        },
        step2: {
          value: 0.5
        },
        step3: {
          value: 1.0
        }
      },
      glowOptions: {
        opacity: {
          value: 1.0
        },
        color1: {
          value: new Color("hsl(209, 100%, 60%)")
        },
        color2: {
          value: new Color("hsl(209, 100%, 100%)")
        },
        step1: {
          value: 0.0
        },
        step2: {
          value: 1.5
        }
      },
      ringColor: "hsl(219, 100%, 37%)",
      lightColors: ["hsl(237, 90%, 80%)", "hsl(237, 90%, 100%)"]
    };
  },
  computed: {
    content() {
      return this.$t("sky");
    }
  },

  created() {
    this.setIndexFromMenu(5);
  },

  metaInfo() {
    return this.$t("share").sky;
  },
  methods: {
    makeCloudTransparent(model) {
      model.traverse(child => {
        if (child.isMesh) {
          const textureLoader = new TextureLoader();
          const texture = textureLoader.load(
            `${this.$baseUrl}models/sky/cloud/textures/alpha_map_cloud.jpg`
          );
          child.material.transparent = true;
          child.material.format = RGBAFormat;
          child.material.alphaMap = texture;
        }
      });
    },
    setSmallScreenConf() {
      this.scenesConfig = this.scenesConfig.map(conf => {
        if (conf.folder === "drone") {
          conf.scale = 10;
        }

        if (conf.folder === "feathers") {
          conf.position.y = -4;
        }

        return conf;
      });
    },
    ...mapActions(["toggleAutoScroll", "setIndexFromMenu"])
  }
};
</script>

<style lang="scss" scoped>
.sky {
  // background: $bg-sky;
}
</style>
